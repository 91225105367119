import {
  Form,
  useActionData,
  useNavigation,
  type ActionFunctionArgs,
  redirect,
} from "react-router-dom";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { TextField, TextFieldErrorMessage } from "../components/ui/textField";
import { Icon } from "../components/ui/Icon";
import { Button } from "../components/ui/button";
import { createTeam, getTeam, saveTeam } from "../store";
import { generateProof } from "@localfirst/auth";

export function NewTeam() {
  const navigation = useNavigation();
  const { error } = (useActionData() || {}) as { error?: string };
  return (
    <div className="mx-auto max-w-xl pt-16">
      <h1 className="text-4xl font-bold">New Team</h1>
      <p>Teams allow you to collaborate with other people securely.</p>
      <Form method="post" className="mt-8 flex flex-col gap-4">
        <TextField isInvalid={!!error}>
          <Label>Team Name</Label>
          <Input name="teamName" autoFocus />
          {error && <TextFieldErrorMessage>{error}</TextFieldErrorMessage>}
        </TextField>
        <Button
          type="submit"
          className="self-end"
          isDisabled={navigation.state === "submitting"}
        >
          {navigation.state === "submitting" ? (
            <Icon name="loader-2" className="animate-spin" />
          ) : (
            "Create"
          )}
        </Button>
      </Form>
    </div>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  const data = await request.formData();
  const teamName = data.get("teamName") as string;
  if (!teamName) {
    return { error: "Team name is required" };
  }

  const team = await createTeam(teamName);
  // Add all of this member's devices to the team
  const userTeam = await getTeam("userTeam");
  userTeam.members()[0].devices?.forEach((device) => {
    const invitation = team.inviteDevice();
    const proof = generateProof(invitation.seed);
    team.admitDevice(proof, device);
  });

  await saveTeam(team.id);

  userTeam.emit("shareTeams");

  return redirect(`/team/${team.id}`);
}

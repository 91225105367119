import {
  Form,
  redirect,
  useActionData,
  useNavigation,
  type ActionFunctionArgs,
} from "react-router-dom";
import { Button } from "../components/ui/button";
import { TextField, TextFieldErrorMessage } from "../components/ui/textField";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import Aside from "../components/Aside";
import { Icon } from "../components/ui/Icon";
import { createUser, type DeviceWithSecrets } from "@localfirst/auth";
import { store, createTeam } from "../store";

export function NewIdentity() {
  const navigation = useNavigation();
  const { error } = (useActionData() || {}) as { error?: string };
  return (
    <Form method="post" className="mt-8 flex flex-col gap-4">
      <TextField isInvalid={!!error}>
        <Label>Username</Label>
        <Input name="username" autoFocus />
        {error && <TextFieldErrorMessage>{error}</TextFieldErrorMessage>}
      </TextField>
      <Button
        type="submit"
        className="self-end"
        isDisabled={navigation.state === "submitting"}
      >
        {navigation.state === "submitting" ? (
          <Icon name="loader-2" className="animate-spin" />
        ) : (
          "Create"
        )}
      </Button>
      <Aside mode="question" title="Where's my password?">
        <p>
          There isn't one! Your identity is secured by a cryptographic key pair
          stored on your device. The only time this key pair is shared is when
          you attach another device to your identity.
        </p>
      </Aside>
    </Form>
  );
}

export const action = async ({ request }: ActionFunctionArgs) => {
  const data = await request.formData();
  const username = data.get("username") as string;
  if (!username) {
    return { error: "Username is required" };
  }

  const device = (await store.getItem("device")) as DeviceWithSecrets;
  if (!device) throw new Error("No device found");
  const user = createUser(username);
  device.userId = user.userId;
  device.keys.name = `${user.userId}::${device.deviceName}`;

  await store.setItem("user", user);
  await store.setItem("device", device);
  await createTeam("userTeam");

  return redirect("/");
};

import {
  loadTeam as loadAuthTeam,
  createTeam as createAuthTeam,
  type KeysetWithSecrets,
  type Team,
  type UserWithSecrets,
  type DeviceWithSecrets,
  type EncryptedEnvelope,
  Connection,
  symmetric,
  type Base58,
} from "@localfirst/auth";
import EventEmitter from "eventemitter3";
import { createInstance } from "localforage";
import PartySocket from "partysocket";
import { useEffect } from "react";
import { useRevalidator } from "react-router-dom";

declare const PARTYKIT_HOST: string;

export const store = createInstance({
  name: "e2e-chat",
});

const revalidationEmitter = new EventEmitter();

const teamsMap = new Map<string, Team>();

type IncomingMessage =
  | {
      type: "newConnection";
      id: string;
    }
  | {
      type: "peerSignal";
      senderId: string;
      message: string;
    }
  | { type: "action"; message: EncryptedEnvelope }
  | { type: "shareTeam"; message: EncryptedEnvelope };
type EncryptedAction = { type: "newMessage"; message: string };

export const teamConnections = new Map<string, Connection>();

export async function saveTeam(key: string) {
  const team = teamsMap.get(key);
  if (!team) throw new Error("Team not found");
  await store.setItem(key, {
    team: team.save(),
    keys: team.teamKeys(),
  });
}
export async function getTeam(key: string) {
  if (teamsMap.has(key)) return teamsMap.get(key) as Team;

  const user = (await store.getItem("user")) as UserWithSecrets;
  const device = (await store.getItem("device")) as DeviceWithSecrets;
  const { team: teamData, keys: teamKeys } = (await store.getItem(key)) as {
    team: any;
    keys: KeysetWithSecrets;
  };

  const team = loadAuthTeam(teamData, { user, device }, teamKeys);
  await setUpTeam(team);
  return team;
}

export async function createTeam(teamName: string) {
  const user = (await store.getItem("user")) as UserWithSecrets;
  const device = (await store.getItem("device")) as DeviceWithSecrets;

  const team = createAuthTeam(teamName, { user, device });

  await setUpTeam(team);
  return team;
}

export async function loadTeam(teamData: string, keys: KeysetWithSecrets) {
  const user = (await store.getItem("user")) as UserWithSecrets;
  const device = (await store.getItem("device")) as DeviceWithSecrets;

  const team = loadAuthTeam(teamData, { user, device }, keys);

  await setUpTeam(team);
  return team;
}

const excludedKeys = ["user", "device", "userTeam"];
export async function getTeams() {
  const user = (await store.getItem("user")) as UserWithSecrets;
  const device = (await store.getItem("device")) as DeviceWithSecrets;

  const teams: { name: string; id: string }[] = [];
  await store.iterate((value: any, key) => {
    if (!excludedKeys.includes(key)) {
      const team = loadAuthTeam(
        value.team,
        {
          user,
          device,
        },
        value.keys,
      );
      teams.push({
        name: team.teamName,
        id: key,
      });
    }
  });
  return teams;
}
export async function getFullTeams() {
  const teams: { id: string; team: any; keys: KeysetWithSecrets }[] = [];
  await store.iterate((value: any, key) => {
    if (!excludedKeys.includes(key)) {
      teams.push({
        id: key,
        team: value.team,
        keys: value.keys,
      });
    }
  });
  return teams;
}

async function setUpTeam(team: Team) {
  const user = (await store.getItem("user")) as UserWithSecrets;
  const device = (await store.getItem("device")) as DeviceWithSecrets;

  const key = team.teamName === "userTeam" ? "userTeam" : team.id;
  if (teamsMap.has(key)) return;

  teamsMap.set(key, team);
  await saveTeam(key);

  team.addListener("updated", async () => {
    console.log("Team saved");
    saveTeam(key);
  });
  // Join the team party
  const socket = new PartySocket({
    host: PARTYKIT_HOST,
    room: team.id,
  });

  async function shareTeams() {
    if (key === "userTeam") {
      console.log("Sharing teams");
      const teams = await getFullTeams();
      teams.forEach((outgoingTeam) => {
        socket.send(
          JSON.stringify({
            type: "shareTeam",
            message: team.encrypt(outgoingTeam),
          }),
        );
      });
    }
  }

  if (key === "userTeam") {
    team.addListener("shareTeams", () => {
      shareTeams();
    });
  }

  socket.addEventListener("message", async (event) => {
    const message: IncomingMessage = JSON.parse(event.data);
    let state = "idle";
    function setState(newState: any) {
      if (newState === "connected") {
        if (state === "synchronizing") {
          revalidationEmitter.emit("revalidate");
        }
      }
      state = newState;
    }
    function createConnection(destinationId: string) {
      function sendMessage(message: string) {
        // console.log("Sending message:", JSON.parse(message));
        socket.send(
          JSON.stringify({
            type: "peerSignal",
            destinationId,
            message,
          }),
        );
      }
      let connection: Connection;
      const invitationSeed = undefined;
      if (invitationSeed) {
        connection = new Connection({
          sendMessage,
          context: {
            user,
            userId: user.userId,
            userName: user.userName,
            device,
            invitationSeed,
          },
        });
      } else if (team) {
        connection = new Connection({
          sendMessage,
          context: { user, device, team },
        });
      } else {
        throw new Error("Not enough information to create a connection");
      }
      console.log("Starting connection");
      connection.start();
      connection
        .on("joined", ({ team, user }) => {
          setState("joined");
        })
        .on("connected", () => {
          setState("connected");
        })
        .on("change", (state) => {
          setState(state);
        })
        .on("localError", (type) => {
          setState("error");
        })
        .on("remoteError", (type) => {
          setState("error");
        })
        .on("disconnected", (event) => {
          setState("disconnected");
        });
      teamConnections.set(`${key}:${destinationId}`, connection);

      return connection;
    }
    switch (message.type) {
      case "newConnection": {
        console.log("New connection", message.id);
        createConnection(message.id);
        await shareTeams();
        break;
      }
      case "peerSignal": {
        let connection = teamConnections.get(`${key}:${message.senderId}`);
        if (!connection) {
          connection = createConnection(message.senderId);
        }
        // console.log("Received message:", JSON.parse(message.message));
        connection?.deliver(message.message);
        break;
      }
      case "shareTeam": {
        console.log(key, "Got Share Team");
        if (key !== "userTeam") return;
        const team = await getTeam("userTeam");
        const decryptedTeam = team.decrypt(message.message) as {
          team: any;
          id: string;
          keys: KeysetWithSecrets;
        };
        const existingTeams = await getTeams();
        if (!existingTeams.find((t) => t.id === decryptedTeam.id)) {
          await store.setItem(decryptedTeam.id, {
            team: decryptedTeam.team,
            keys: decryptedTeam.keys,
          });
        }
        revalidationEmitter.emit("revalidate");
        break;
      }
      case "action": {
        if (!team) return;
        const action = JSON.parse(
          team.decrypt(message.message) as string,
        ) as EncryptedAction;
        console.log(action);
        // switch (action.type) {
        //   case "newMessage":
        //     setChats([...chats, action.message]);
        //     break;
        // }
      }
      default:
        return;
    }
  });

  await shareTeams();
}

export function Revalidator() {
  const { revalidate } = useRevalidator();

  useEffect(() => {
    revalidationEmitter.on("revalidate", revalidate);
    return () => {
      revalidationEmitter.off("revalidate", revalidate);
    };
  });
  return null;
}

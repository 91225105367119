import {
  redirect,
  type ActionFunctionArgs,
  useNavigation,
  useActionData,
  Form,
} from "react-router-dom";
import { TextField, TextFieldErrorMessage } from "../components/ui/textField";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Icon } from "../components/ui/Icon";

export function AcceptInvite() {
  const navigation = useNavigation();
  const { error } = (useActionData() || {}) as { error?: string };
  const inviteCode =
    typeof window !== "undefined" ? location.hash.replace("#", "") : "";
  return (
    <div className="mx-auto max-w-xl pt-16">
      <h1 className="text-4xl font-bold">Join a Team</h1>

      <Form method="post" className="mt-8 flex flex-col gap-4">
        <p>Enter the invite code that was sent to you.</p>
        <TextField isInvalid={!!error} defaultValue={inviteCode}>
          <Label>Invite Code</Label>
          <Input
            name="inviteCode"
            autoFocus
            autoCapitalize="none"
            autoComplete="none"
            autoCorrect="none"
          />
          {error && <TextFieldErrorMessage>{error}</TextFieldErrorMessage>}
        </TextField>
        <Button
          type="submit"
          className="self-end"
          isDisabled={navigation.state === "submitting"}
        >
          {navigation.state === "submitting" ? (
            <Icon name="loader-2" className="animate-spin" />
          ) : (
            "Submit"
          )}
        </Button>
      </Form>
    </div>
  );
}
export function action({}: ActionFunctionArgs) {
  // const team = getTeam();
  return redirect(`/team/${t.id}`);
}

import { useEffect, useState } from "react";

export function useWebShare({
  title,
  text,
  url,
}: {
  title: string;
  text: string;
  url: string;
}) {
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    if (typeof navigator.canShare === "function") {
      setCanShare(navigator.canShare({ title, text, url }));
    }
  }, [title, text, url]);

  async function share() {
    try {
      await navigator.share({ title, text, url });
    } catch (err) {
      console.error(err);
    }
  }

  return [share, canShare] as const;
}

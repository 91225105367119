import { Outlet, useRouteError } from "react-router-dom";
import { Revalidator } from "../store";

export function Root() {
  return (
    <div className="mx-auto min-h-screen max-w-4xl bg-white px-4 text-black dark:bg-slate-950 dark:text-white">
      <Outlet />
      <Revalidator />
    </div>
  );
}
export function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}

import { Outlet } from "react-router-dom";
import { ButtonLink } from "../components/ui/button";

export function Welcome() {
  return (
    <div className="mx-auto max-w-xl pt-16">
      <h1 className="text-4xl font-bold">Welcome!</h1>
      <h2 className="text-2xl font-bold">Let's set up your identity.</h2>
      <Outlet />
    </div>
  );
}

export function WelcomeChoice() {
  return (
    <div className="mt-8 flex items-center justify-around">
      <ButtonLink to="identity">Create a New Identity</ButtonLink>
      <div className="h-32 w-px bg-gray-700 dark:bg-gray-300"></div>
      <ButtonLink to="invite">Enter an Identity Invite</ButtonLink>
    </div>
  );
}

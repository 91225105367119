import {
  Form,
  useActionData,
  useNavigation,
  type ActionFunctionArgs,
  redirect,
} from "react-router-dom";
import { Button } from "../components/ui/button";
import { TextField, TextFieldErrorMessage } from "../components/ui/textField";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { Icon } from "../components/ui/Icon";
import PartySocket from "partysocket";
import {
  generateProof,
  type Base58,
  type DeviceWithSecrets,
  redactDevice,
  asymmetric,
} from "@localfirst/auth";
import { loadTeam, store } from "../store";

declare const PARTYKIT_HOST: string;

export function DeviceInvite() {
  const navigation = useNavigation();
  const { error } = (useActionData() || {}) as { error?: string };
  const inviteCode =
    typeof window !== "undefined" ? location.hash.replace("#", "") : "";
  return (
    <Form method="post" className="mt-8 flex flex-col gap-4">
      <p>Enter the invite code you generated on your other device.</p>
      <TextField isInvalid={!!error} defaultValue={inviteCode}>
        <Label>Invite Code</Label>
        <Input
          name="inviteCode"
          autoFocus
          autoCapitalize="none"
          autoComplete="none"
          autoCorrect="none"
        />
        {error && <TextFieldErrorMessage>{error}</TextFieldErrorMessage>}
      </TextField>
      <Button
        type="submit"
        className="self-end"
        isDisabled={navigation.state === "submitting"}
      >
        {navigation.state === "submitting" ? (
          <Icon name="loader-2" className="animate-spin" />
        ) : (
          "Submit"
        )}
      </Button>
    </Form>
  );
}
type IncomingMessage = {
  type: "invite";
  encryptedTeam: Base58;
};
export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const device = (await store.getItem("device")) as DeviceWithSecrets;
  const inviteCode = formData.get("inviteCode") as string;
  if (!inviteCode) {
    return { error: "Invite code is required" };
  }

  const proof = generateProof(inviteCode);

  const socket = new PartySocket({
    host: PARTYKIT_HOST,
    room: `invite:${proof.id}`,
  });

  socket.send(
    JSON.stringify({
      type: "proof",
      proof,
      device: redactDevice(device),
    }),
  );

  try {
    const redirectTo = (await Promise.race([
      new Promise((res) => {
        socket.addEventListener("message", async (event) => {
          console.log("Listening for invite");
          const message: IncomingMessage = JSON.parse(event.data);
          if (message.type === "invite") {
            console.log("Decrypting invite");
            const secret = asymmetric.decrypt({
              cipher: message.encryptedTeam,
              recipientSecretKey: device.keys.encryption.secretKey,
            });
            if (typeof secret !== "string") throw new Error("Invalid secret");
            const { team, keys, user } = JSON.parse(secret);
            console.log("Loading team");

            await store.setItem("user", user);
            device.userId = user.userId;
            device.keys.name = `${user.userId}::${device.deviceName}`;

            await store.setItem("device", device);
            const loadedTeam = await loadTeam(team, keys);

            console.log("Sending complete message");
            socket.send(
              JSON.stringify({ type: "complete", teamId: loadedTeam.id }),
            );
            console.log("Team saved:", loadedTeam.id);
            return res(`/`);
          }
        });
      }),
      new Promise((res, fail) => setTimeout(fail, 15000, "timeout")),
    ])) as string;
    return redirect(redirectTo || "/");
  } catch (e) {
    console.error(e);
    return { error: "Timeout accepting team invitation" };
  }
}

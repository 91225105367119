import {
  Await,
  Link,
  defer,
  useLoaderData,
  useRouteLoaderData,
} from "react-router-dom";
import { ButtonLink } from "../components/ui/button";
import { getTeam, getTeams, store } from "../store";
import type { DeviceWithSecrets, UserWithSecrets } from "@localfirst/auth";
import { Suspense } from "react";

export async function loader() {
  const devices = Promise.resolve().then(async () => {
    const userTeam = await getTeam("userTeam");
    const user = (await store.getItem("user")) as UserWithSecrets;
    const device = (await store.getItem("device")) as DeviceWithSecrets;

    const teamMember = userTeam.members().find((m) => m.userId === user.userId);
    return (teamMember?.devices || []).map((d) => ({
      deviceName: d.deviceName,
      thisDevice: device.keys.signature.publicKey === d.keys.signature,
    }));
  });

  const teams = getTeams();
  return defer({ devices, teams });
}

export function Home() {
  const { user } = useRouteLoaderData("root") as any;
  const { devices, teams } = useLoaderData() as {
    devices: { deviceName: string; thisDevice: boolean }[];
    teams: { id: string; name: string }[];
  };

  return (
    <>
      <h1 className="text-2xl font-bold">Welcome, {user.userName}!</h1>
      <div className="flex gap-4">
        <div>
          <p>Your Devices:</p>
          <ul className="list-disc pl-4">
            <Suspense>
              <Await resolve={devices}>
                {(devices) =>
                  devices.map((d: any) => (
                    <li key={d.deviceName}>
                      {d.thisDevice ? <span title="This device">👉 </span> : ""}
                      {d.deviceName}
                    </li>
                  ))
                }
              </Await>
            </Suspense>
          </ul>
        </div>
        <div>
          <p>Your Teams:</p>
          <ul className="list-disc pl-4">
            <Suspense>
              <Await resolve={teams}>
                {(teams) =>
                  teams.map((t: any) => (
                    <li key={t.id}>
                      <Link to={`/team/${t.id}`}>{t.name}</Link>
                    </li>
                  ))
                }
              </Await>
            </Suspense>
          </ul>
        </div>
      </div>
      <div className="mt-4 flex gap-4">
        <ButtonLink to="/new-team">Create a Team</ButtonLink>
        <ButtonLink to="/accept-invite">Accept a Team Invite</ButtonLink>
        <ButtonLink to="/connect-device">Connect a New Device</ButtonLink>
      </div>
    </>
  );
}

import {
  useLoaderData,
  useNavigate,
  type LoaderFunctionArgs,
  redirect,
} from "react-router-dom";
import {
  asymmetric,
  generateProof,
  type Device,
  type ProofOfInvitation,
  type User,
} from "@localfirst/auth";
import { getTeam, store } from "../store";
import usePartySocket from "partysocket/react";
import { Input } from "../components/ui/input";
import { useCopyToClipboard } from "../hooks/useCopyToClipboard";
import { useWebShare } from "../hooks/useWebShare";
import { Button } from "../components/ui/button";
import QRCode from "react-qr-code";
import Aside from "../components/Aside";

declare const PARTYKIT_HOST: string;

export type IncomingMessage =
  | {
      type: "proof";
      proof: ProofOfInvitation;
      device: Device;
    }
  | { type: "complete"; teamId: string };

export const loader = async () => {
  const user = (await store.getItem("user")) as User;

  if (!user) throw redirect("/welcome");

  const inviteCode = `${user.userName}-${randomSeed()}`.toLowerCase();

  const invitation = generateProof(inviteCode);

  return { inviteCode, invitationId: invitation.id };
};

export function ConnectDevice() {
  const { inviteCode, invitationId } = useLoaderData() as {
    inviteCode: string;
    invitationId: string;
  };

  const navigate = useNavigate();

  const socket = usePartySocket({
    host: PARTYKIT_HOST,
    room: `invite:${invitationId}`,
    async onMessage(event) {
      const message: IncomingMessage = JSON.parse(event.data);
      switch (message.type) {
        case "proof": {
          const team = await getTeam("userTeam");
          team.inviteDevice({ seed: inviteCode });
          const proof = team.validateInvitation(message.proof);
          if (proof.isValid) {
            const device = message.device;
            device.userId = team.userId;
            device.keys.name = `${team.userId}::${device.deviceName}`;

            team.admitDevice(message.proof, device);
            const encryptedTeam = asymmetric.encrypt({
              secret: JSON.stringify({
                team: team.save(),
                keys: team.teamKeys(),
                user: await store.getItem("user"),
              }),
              recipientPublicKey: message.device.keys.encryption,
            });
            socket.send(JSON.stringify({ type: "invite", encryptedTeam }));
          }
          break;
        }
        case "complete": {
          const teamId = message.teamId;
          console.log("Team saved:", teamId);

          navigate(`/`);
          break;
        }
      }
    },
  });

  const [copyToClipboard, copied] = useCopyToClipboard();
  const url = `${window.location.origin}/welcome/invite/#${inviteCode}`;
  const [share, canShare] = useWebShare({
    title: "E2E Chat Invite",
    text: "Follow this link to accept my invitation!",
    url,
  });
  return (
    <>
      <h1 className="text-2xl font-bold">Attach a New Device</h1>
      <p>
        Enter this invite code into your other device to connect it to this
        identity.
      </p>
      <Input
        value={inviteCode}
        readOnly
        onClick={() => copyToClipboard(inviteCode)}
      ></Input>
      {copied ? <p>Copied! 👍</p> : false}
      {canShare ? (
        <Button className="mt-4" onPress={share}>
          Share
        </Button>
      ) : (
        false
      )}
      <div>
        <div className="mt-4 inline-block bg-white p-4">
          <QRCode value={url} />
        </div>
      </div>
      <Aside>
        <p>
          Keep this page open until the other device is connected.
          {/* TODO: Make it possible to turn on server mode from here, or somewhere else. */}
        </p>
      </Aside>
    </>
  );
}
export const randomSeed = () =>
  "0000".replaceAll("0", () => Math.floor(Math.random() * 10).toString());

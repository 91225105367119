import React from "react";

export function useCopyToClipboard() {
  const [copied, setCopied] = React.useState(false);
  const timeoutRef = React.useRef<any>();
  const copyToClipboard = async (str: string) => {
    await navigator.clipboard.writeText(str);
    setCopied(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  React.useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return [copyToClipboard, copied] as const;
}

import { createRoot } from "react-dom/client";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
  useLoaderData,
} from "react-router-dom";
import { Team, createDevice, type User } from "@localfirst/auth";
import { randomName } from "./utils/randomName";
import { Buffer } from "buffer";
import { Welcome, WelcomeChoice } from "./routes/Welcome";
import {
  DeviceInvite,
  action as deviceInviteAction,
} from "./routes/DeviceInvite";
import { NewIdentity, action as newIdentityAction } from "./routes/NewIdentity";
import { getTeam, store } from "./store";
import { Home, loader as homeLoader } from "./routes/Home";
import {
  ConnectDevice,
  loader as connectDeviceLoader,
} from "./routes/ConnectDevice";
import { Root, ErrorPage } from "./routes/Root";
import { NewTeam, action as newTeamAction } from "./routes/NewTeam";
import { AcceptInvite, action } from "./routes/AcceptInvite";

declare global {
  interface Window {
    Buffer: typeof Buffer;
  }
}
window.Buffer = Buffer;

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,

    loader: async ({ request }) => {
      const user = await store.getItem("user");
      const device = await store.getItem("device");
      if (!new URL(request.url).pathname.startsWith("/welcome")) {
        if (!user) throw redirect("/welcome");
      }

      return { user, device };
    },
    children: [
      {
        path: "/",
        element: <Home />,
        loader: homeLoader,
      },
      {
        path: "/welcome",
        element: <Welcome />,
        children: [
          {
            index: true,
            element: <WelcomeChoice />,
          },
          {
            path: "invite",
            element: <DeviceInvite />,
            action: deviceInviteAction,
          },
          {
            path: "identity",
            element: <NewIdentity />,
            action: newIdentityAction,
          },
        ],
      },
      {
        path: "/connect-device",
        element: <ConnectDevice />,
        loader: connectDeviceLoader,
      },
      {
        path: "/new-team",
        element: <NewTeam />,
        action: newTeamAction,
      },
      {
        path: "/accept-invite",
        element: <AcceptInvite />,
        action: action,
      },
      { path: "/team/:teamId", element: <TeamView />, loader: teamViewLoader },
    ],
  },
]);

function TeamView() {
  const { team, user } = useLoaderData() as { team: Team; user: User };
  return (
    <div className="mx-auto max-w-xl pt-16">
      <h1 className="text-4xl font-bold">{team.teamName}</h1>
      <p>Members:</p>
      <ul>
        {team.members().map((member) => (
          <li key={member.userId}>
            {member.userId === user.userId ? "👉 " : ""}
            {member.userName}
          </li>
        ))}
      </ul>
    </div>
  );
}

async function teamViewLoader({ params }: any) {
  const team = await getTeam(params.teamId);
  const user = await store.getItem("user");
  return { team, user };
}

store.ready().then(async () => {
  // Make sure we have a device record stored
  let device = await store.getItem("device");
  if (!device) {
    const name = randomName();
    const device = createDevice("", name);
    await store.setItem("device", device);
  }
  // Render your React component instead
  const root = createRoot(document.getElementById("app")!);
  root.render(<RouterProvider router={router} />);
});

// if ("serviceWorker" in navigator) {
//   // Use the window load event to keep the page load performant
//   async function loadSW() {
//     return navigator.serviceWorker
//       .register("/worker.js")
//       .then(() => navigator.serviceWorker.ready)
//       .catch((error) => {
//         console.error("Service worker registration failed", error);
//       });
//   }

//   if (
//     document.readyState === "complete" ||
//     document.readyState === "interactive"
//   ) {
//     loadSW();
//   } else {
//     window.addEventListener("load", loadSW);
//   }
// }
